<template>
    <div class="h-screen grid m-0">
        <div class="hidden md:block col-6" id="imgCol" style="background-size: cover;"></div>
        <div class="col-12 md:col-6 flex flex-column">
            <div v-if="!registerComplete" class="flex flex-column align-items-center justify-content-center flex-grow-1">
                <div class="text-center mb-2 w-11 lg:w-6">
                    <img src="/images/logo.png" alt="Comunidae logo" height="75" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3">{{ $appState.estate?.name }}</div>
                </div>
                <div class="w-11 lg:w-6">
                    <p class="text-center font-bold mb-2">Rellena el siguiente formulario para registrarte en la comunidad.</p>

                    <div class="flex align-items-center justify-content-center mb-2">
                        <router-link to="/login" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">¿Ya tienes cuenta? Inicia sesión.</router-link>
                    </div>

                    <label for="name" class="block text-900 font-medium mb-2">Nombre</label>
                    <InputText v-model="v$.name.$model" id="name" type="text" class="w-full mb-4" :class="{'p-invalid': v$.name.$error}" required />
                    <div v-for="error of v$.name.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="email" class="block text-900 font-medium mb-2">Email</label>
                    <InputText v-model.trim="v$.email.$model" id="email" type="email" class="w-full mb-4" :class="{'p-invalid': v$.email.$error}" required />
                    <div v-for="error of v$.email.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="dwelling" class="block text-900 font-medium mb-2">Vivienda</label>
                    <InputText v-model.trim="v$.dwelling.$model" id="dwelling" type="text" class="w-full mb-4" :class="{'p-invalid': v$.dwelling.$error}" required />
                    <div v-for="error of v$.dwelling.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="phone" class="block text-900 font-medium mb-2">Teléfono</label>
                    <InputText v-model.trim="v$.phone.$model" id="phone" class="w-full mb-4" :class="{'p-invalid': v$.phone.$error}" />
                    <div v-for="error of v$.phone.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="password" class="block text-900 font-medium mb-2">Contraseña <i class="pi pi-info-circle ml-1" v-tooltip="'Mínimo 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 símbolo'" /></label>
                    <Password v-model="v$.password.$model" :toggleMask="true" id="password" class="mb-4" required />
                    <div v-for="error of v$.password.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <label for="password2" class="block text-900 font-medium mb-2">Repite la contraseña</label>
                    <Password v-model="v$.password2.$model" :toggleMask="true" id="password2" class="mb-4" required />
                    <div v-for="error of v$.password2.$errors" :key="error.$uid" class="mb-4">
                        <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                    </div>

                    <div class="flex flex-row items-center mb-4">
                        <Checkbox v-model="communications" :binary="true" id="communications" class="mr-2" />
                        <label for="communications">Envío de comunicaciones</label>
                    </div>

                    <div class="flex flex-row items-center mb-4">
                        <Checkbox v-model="v$.termsAccepted.$model" :binary="true" id="termsAccepted" class="mr-2" required />
                        <label for="termsAccepted">He leído y acepto los términos y condiciones de uso</label>
                    </div>
                    <div class="flex flex-row items-center mb-4">
                        <Checkbox v-model="v$.privacyAccepted.$model" :binary="true" id="privacyAccepted" class="mr-2" required />
                        <label for="privacyAccepted">He leído y acepto la política de privacidad</label>
                    </div>

                    <div class="mb-4" v-if="registerError">
                        <small class="p-error font-bold">{{ registerError }}</small>
                        <ul v-if="serverErrors.length > 0">
                            <li v-for="(serverError, i) in serverErrors" v-bind:key="i"><small class="p-error font-bold">{{ serverError }}</small></li>
                        </ul>
                    </div>
                    <Button label="Enviar" icon="pi pi-send" class="w-full" @click="login" :disabled="canRegister"></Button>
                </div>
            </div>
            <div v-if="registerComplete" class="flex flex-column align-items-center justify-content-center flex-grow-1">
                <div class="text-center mb-2 w-11 lg:w-6">
                    <img src="/images/logo.png" alt="Comunidae logo" height="75" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3">{{ $appState.estate?.name }}</div>
                </div>
                <h3>¡Registro completado!</h3>
                <p class="font-bold">Gracias por registrarte en {{ $appState.estate.name }}.</p>
                <p class="font-bold">La administración de la comunidad revisará tu solicitud de registro y activará tu cuenta.</p>
                <p class="font-bold">Recibirás un correo electrónico cuando se active tu cuenta.</p>
            </div>
            <div class="flex flex-column md:flex-row align-items-center justify-content-center gap-2 md:gap-5 mt-4 lg:mt-0">
                <a v-for="page in $appState.estate?.footerLinks" :key="page.id" :href="page.link" target="_blank" rel="noopener noreferrer">{{ page.name }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import useVuelidate from '@vuelidate/core';
import ApiService from '@/service/ApiService.js';
import AuthService from '@/service/AuthService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import { required, email, minLength, maxLength, sameAs } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import CustomValidators from '@/customValidators';

export default {
    beforeRouteEnter() {
        if (RouteGuardService.isLoggedIn() === true) {
            return '/';
        }

        return true;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: '',
            email: '',
            dwelling: '',
            phone: '',
            password: '',
            password2: '',
            communications: true,
            termsAccepted: false,
            privacyAccepted: false,
            authService: null,
            validationMessages: {},
            registerError: null,
            responseErrors: [],
            working: false,
            registerComplete: false
        };
    },
    created() {
        this.authService = new AuthService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    mounted() {
        let $el = document.querySelector('#imgCol');

        if ($el) {
            $el.style.backgroundImage = 'url(' + ApiService.getHost() + '/estates/' + this.$appState.domainSlug + '/bg)';
        }
    },
    validations() {
        return {
            name: { required, minLength: minLength(2), maxLength: maxLength(255) },
            email: { required, email, maxLength: maxLength(255) },
            dwelling: { minLength: minLength(2), maxLength: maxLength(255) },
            phone: { minLength: minLength(9), maxLength: maxLength(15) },
            password: { required, minLength: minLength(8), password: CustomValidators.password, maxLength: maxLength(255) },
            password2: { required, sameAs: sameAs(this.password), maxLength: maxLength(255) },
            termsAccepted: { required },
            privacyAccepted: { required },
        };
    },
    computed: {
        canRegister() {
            return this.v$.name.$errors.length !== 0
                || this.v$.email.$errors.length !== 0
                || this.v$.dwelling.$errors.length !== 0
                || this.v$.phone.$errors.length !== 0
                || this.v$.password.$errors.length !== 0
                || this.v$.password2.$errors.length !== 0
                || this.name.length < 2
                || this.email.length === 0
                || this.dwelling.length < 2
                || this.phone.length < 9
                || this.phone.length > 15
                || this.password.length < 8
                || this.password2.length < 8
                || !this.termsAccepted
                || !this.privacyAccepted
                || this.working;
        },
    },
    methods: {
		login() {
            if (!this.canRegister) {
                this.working = true;
                this.registerError = null;
                this.serverErrors = [];
                let w = new Worker('/pwhash-worker.js');
                let salt;

                this.authService.fetchUserNonce(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.email
                ).then(response => {
                    if (response.data.salt && response.data.nonce) {
                        salt = window.sodium.from_hex(response.data.salt);
                        w.postMessage([salt, this.password]);
                    } else {
                        this.registerError = 'ERROR L-04: Ha ocurrido un error al registrar tus datos';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {email: this.email}),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.registerError = 'ERROR L-01: Ha ocurrido un error al registrar tus datos';
                });

                w.onmessage = (e) => {
                    let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                    let salt_signature = window.sodium.crypto_sign_detached(salt, kp.privateKey);

                    this.authService.doSignUp(
                        this.$appState.estate.id,
                        this.name,
                        this.email,
                        this.dwelling,
                        this.phone,
                        this.communications,
                        this.termsAccepted,
                        this.privacyAccepted,
                        window.sodium.to_hex(salt),
                        window.sodium.to_hex(kp.publicKey),
                        window.sodium.to_hex(salt_signature)
                    ).then(response => {
                        if (response.data.user) {
                            this.registerComplete = true;
                        } else {
                            this.registerError = 'ERROR L-03: Ha ocurrido un error al registrar tus datos';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                email: this.email,
                                name: this.name,
                                dwelling: this.dwelling,
                                phone: this.phone,
                                communications: this.communications,
                                termsAccepted: this.termsAccepted,
                                privacyAccepted: this.privacyAccepted,
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.registerError = 'ERROR L-02: Ha ocurrido un error al registrar tus datos';

                        if (error.response.data && error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(ek => {
                                this.serverErrors.push(...error.response.data.errors[ek]);
                            });
                        }
                    });
                };
            }
		}
	}
};
</script>
<style scoped>
</style>